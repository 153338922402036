<template>
  <div>
    <h3>Calificaciones</h3>
    <h4>¿Qué opinan nuestros usuarios de esta experiencia?</h4>

    <div
      v-for="(comentario, i) in comentariosOrdenadosPorFecha"
      :key="i"
      class="d-flex my-4"
    >
      <div>
        <v-icon size="100">mdi-account-circle </v-icon>
        <p v-if="usuarios[i]" class="text-capitalize text-center">{{ usuarios[i].nombre }}</p>
      </div>

      <div>
        <v-rating
          readonly
          size="30"
          :value="comentario.rating"
          background-color="#C4C4C4"
          color="warning"
          half-increments
        ></v-rating>
        <span class="pl-3">{{ convertToDate(comentario.date.toDate()) }}</span>
        <p class="pl-3">{{ comentario.comentario }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import moment from "moment";
moment.locale("es");

export default {
  name: "Calificaciones de la Experiencia",
  props: ["comentarios"],
  data() {
    return {
      usuarios: [],
    };
  },
  mounted() {
    this.getUsersData();
  },
  methods: {
    async getUsersData() {
      const emails = this.comentarios.map((c) => c.usuario);
      const usuarios = [];
      for (let email of emails) {
        const { docs, empty } = await firebase
          .firestore()
          .collection("usuarios")
          .where("email", "==", email)
          .get();

        if (empty) {
          usuarios.push({ email, foto: null, nombre: "Anónimo" });
          continue;
        }

        const userData = docs[0].data();

        const { photoURL, displayName } = userData;
        usuarios.push({ foto: photoURL, nombre: displayName, email });
      }
      this.usuarios = usuarios;
    },

    convertToDate(date) {
      return moment(date).format("LL");
    },
  },

  computed: {
    comentariosOrdenadosPorFecha() {
      const { comentarios } = this;
      if (!comentarios || !comentarios.length) return [];
      return comentarios.sort((a, b) => {
        return b.date.seconds - a.date.seconds;
      });
    },
  },
};
</script>

<style scoped>

span{
  color: gray
}
</style>
