<template>
  <v-carousel cycle height="400" hide-delimiter-background show-arrows-on-hover class="rounded-lg">
    <v-carousel-item v-for="(imagenExperiencia, i) in imagenes" :key="i">
      <v-sheet height="100%">
        <div
          class="imagen"
          :style="{ backgroundImage: `url('${imagenExperiencia}')` }"
        ></div>
      </v-sheet>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: "Slider-Detalles",
  props: ["imagenes"],
};
</script>

<style scoped>
.imagen {
  background-size: cover;
  background-position: center;
  height: 100%;
}
</style>
