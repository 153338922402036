<template>
  <v-dialog
    v-model="dialogAvisoEquipajeExtra"
    max-width="500"
    :disabled="abierto"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" @click="abierto = true">
        <slot> </slot>
      </div>
    </template>
    <div class="dialogEquipaje pa-5">
      <p >
        Esta acción está sujeta a aprobación del operador y un reacargo extra.
        Al ingresar tu solicitud de equipaje extra, el equipo Hummingbird te
        contactará para notificar la respuesta y realizar las coordinaciones.
      </p>
      <div @click="dialogAvisoEquipajeExtra = false">
        <Boton texto="Aceptar" width="100%" />
      </div>
    </div>
  </v-dialog>
</template>

<script>
import Boton from "@/components/Botones/Boton";
export default {
  name: "AvisoCobroPorEquipaje",
  components: { Boton },
  data() {
    return {
      dialogAvisoEquipajeExtra: false,
      abierto: false,
    };
  },
  props: {
    activo: {
      type: Boolean,
    },
  },
  mounted() {
    this.dialogAvisoEquipajeExtra = this.activo;
  },
};
</script>

<style lang="scss" scoped>
.dialogEquipaje {
  @include roundedAndElevated;
  // background: $main-white;
}
</style>