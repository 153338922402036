<template>
  <section class="detallesVueloCheckIn">
    <h3 class="text-center pb-5 d-none d-md-block">Detalle del vuelo</h3>
    <div>
      <div class="d-flex align-center">
        <img :src="iconoAeronave" height="30" />
        <p>
          Vuelo No. <span class="font-weight-bold">{{ vuelo.id }}</span>
        </p>
      </div>
      <h2>{{ vuelo.data.origen }} - {{ vuelo.data.destino }}</h2>
      <p>
        {{ pasajeros > 1 ? `${pasajeros} pasajeros` : `${pasajeros} pasajero` }}
      </p>
      <div class="pt-2">
        <p>
          Punto partida:
          <span class="font-weight-bold">
            {{
              vuelo.data.aerodromoOrigen
                ? vuelo.data.aerodromoOrigen
                : vuelo.data.origen
            }}
          </span>
        </p>
        <p v-if="vuelo.data.direccionOrigen">
          Direccion:
          <span class="font-weight-bold direccion">({{ vuelo.data.direccionOrigen }})</span>
        </p>

        <p>
          Fecha:
          <span class="font-weight-bold">{{
            vuelo.data.fecha.split("-").reverse().join("-")
          }}</span>
        </p>
        <p>
          Hora de abordaje:
          <span class="font-weight-bold">{{ vuelo.data.horaDespegue }}</span>
        </p>
        <p>
          Operador:
          <span class="font-weight-bold"
            >{{ vuelo.data.operador.nombre }}
          </span>
        </p>
      </div>
    </div>
    <div class="d-none d-md-block">
      <v-divider class="my-7"></v-divider>
      <div>
        <h3 class="pb-4">¿Para qué necesito dar estos datos?</h3>
        <p>
          Por regulación aeronáutica, el Plan de Vuelo incluye los datos de
          contacto de los pasajeros y un contacto de emergencia. Esto en
          resguardo de la seguridad del vuelo y procedimientos que requieran el
          uso de estos contactos. Hummingbird resguarda la confidencialidad de
          los contactos de emergencia y no serán utilizados de manera comercial
          o promocional.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  name: "DetalleVueloCheckIn",

  data() {
    return {
      vuelo: null,
    };
  },
  props: {
    idVuelo: {
      type: String,
      required: true,
    },
    pasajeros: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState(["icons"]),
    ...mapGetters("Vuelos", ["getVueloById"]),
    ...mapGetters("Experiencias", ["getExperienciaById"]),
    iconoAeronave() {
      let vuelo = this.vuelo || {};
      return vuelo.data.aeronave === "avion"
        ? this.icons.purple.plane
        : this.icons.purple.helicopter.straight;
    },
  },
  methods: {
    getData() {
      // Identificar tipo de vuelo.
      let { idVuelo } = this;

      const vuelo = this.getVueloById(idVuelo);
      const experiencia = this.getExperienciaById(idVuelo);

      this.vuelo = vuelo ? vuelo : experiencia;
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.direccion {
  font-size: 14px ;
}
</style>