<template>
  <div>
    <v-carousel
      hide-delimiter-background
      hide-delimiters
      :show-arrows="false"
      height="fit-content"
      v-model="pasajeroVisible"
      touchless
    >
      <!-- FORMULARIO -->

      <v-carousel-item v-for="(pasajero, i) in listaPasajeros" :key="i">
        <v-sheet light>
          <h2 class="pb-6">
            Datos de
            <span v-if="listaPasajeros.length > 1">los </span>pasajero<span
              v-if="listaPasajeros.length > 1"
              >s</span
            >
          </h2>

          <h3>Nombre y apellido</h3>
          <v-text-field
            outlined
            dense
            :color="colors.mainPurple"
            placeholder="Ejemplo Pedro Martinez"
            v-model="nombre"
            :rules="rules.name.concat(rules.required)"
            validate-on-blur
            disabled
          ></v-text-field>

          <h3>
            Tipo <span class="d-none d-md d-md-inline">y número</span> de
            documento
          </h3>
          <v-row no-gutters align="baseline">
            <v-col cols="12" md="4">
              <v-select
                dense
                :items="documentos"
                v-model="documento"
                outlined
                :color="colors.mainPurple"
                :item-color="colors.mainPurple"
                :rules="rules.documento.concat(rules.required)"
                disabled
              />
            </v-col>
            <v-col
              v-if="documento != documentos[0].text"
              class="d-md-none"
              cols="12"
            >
              <h3>N° de Documento</h3>
            </v-col>

            <v-col cols="1" class="d-none d-md-block"></v-col>
            <v-col v-if="documento === 'RUT'" cols="5" md="3">
              <v-text-field
                dense
                :color="colors.mainPurple"
                placeholder="12345678"
                v-model="rutSinDigitoVerificador"
                :rules="rules.rut.concat(rules.required)"
                outlined
                validate-on-blur
                disabled
              ></v-text-field>
            </v-col>
            <v-col v-if="documento === 'RUT'" class="text-center" cols="1">
              <h2>-</h2>
            </v-col>
            <v-col v-if="documento === 'RUT'" cols="2" md="1">
              <v-text-field
                outlined
                dense
                :color="colors.mainPurple"
                placeholder="0"
                v-model="digitoVerificador"
                :rules="rules.digitoVerificador.concat(rules.required)"
                disabled
              ></v-text-field>
            </v-col>
            <v-col v-else-if="documento === 'Pasaporte'">
              <v-text-field
                dense
                :color="colors.mainPurple"
                placeholder="ABC12345678"
                v-model="numeroPasaporte"
                :rules="rules.required"
                outlined
                validate-on-blur
                prepend-inner-icon="mdi-passport"
                disabled
              ></v-text-field>
            </v-col>
          </v-row>

          <h3>País de origen</h3>
          <v-select
            dense
            :items="paises"
            v-model="paisDeOrigen"
            outlined
            :color="colors.mainPurple"
            :item-color="colors.mainPurple"
            :rules="rules.required"
            disabled
          />
          <h3>Correo electrónico</h3>
          <v-text-field
            outlined
            dense
            :color="colors.mainPurple"
            placeholder="Dirección de domicilio"
            prepend-inner-icon="mdi-email"
            v-model="email"
            :rules="rules.email.concat(rules.required)"
            validate-on-blur
          ></v-text-field>

          <h3>Número móvil</h3>
          <v-text-field
            outlined
            dense
            :color="colors.mainPurple"
            placeholder="Tu teléfono móvil"
            v-model="telefono"
            type="number"
            prepend-inner-icon="mdi-phone"
            :rules="rules.required"
          ></v-text-field>

          <h3>Dirección</h3>
          <v-text-field
            outlined
            dense
            :color="colors.mainPurple"
            placeholder="Dirección de domicilio"
            prepend-inner-icon="mdi-map-marker"
            v-model="direccion"
            :rules="rules.required"
          ></v-text-field>

          <!-- Datos emergencia -->

          <v-divider></v-divider>
          <h2 class="pb-3 pt-8">Contacto de emergencia</h2>

          <h3>Nombre y apellido</h3>
          <v-text-field
            outlined
            dense
            :color="colors.mainPurple"
            placeholder="Ejemplo Pedro Martinez"
            v-model="emergencia.nombre"
            :rules="rules.name.concat(rules.required)"
            validate-on-blur
          ></v-text-field>

          <h3>Número móvil</h3>
          <v-text-field
            outlined
            dense
            :color="colors.mainPurple"
            placeholder="Tu teléfono móvil"
            v-model="emergencia.telefono"
            type="number"
            prepend-inner-icon="mdi-phone"
            :rules="rules.required"
          ></v-text-field>

          <v-divider></v-divider>
          <!-- EQUIPAJE -->

          <h2 class="pb-3 pt-8">¿Qué tipo de equipaje llevas?</h2>

          <v-checkbox
            v-if="equipajePermitido.mano"
            v-model="equipaje.mano"
            :value="true"
            :color="colors.mainPurple"
            class="radioItem pa-5 my-2"
          >
            <template v-slot:label>
              <div class="d-flex align-center">
                <h3>Equipaje de mano</h3>
              </div>
            </template>
          </v-checkbox>

          <v-checkbox
            v-if="equipajePermitido.cabina"
            v-model="equipaje.cabina"
            :value="true"
            :color="colors.mainPurple"
            class="radioItem pa-5 my-2"
          >
            <template v-slot:label>
              <div class="d-flex align-center">
                <h3>Equipaje de cabina</h3>
              </div>
            </template>
          </v-checkbox>

          <AvisoCobroPorEquipaje
            v-if="tipoDeViaje === 'Vuelos'"
            :activo="equipaje.extra"
          >
            <v-checkbox
              v-model="equipaje.extra"
              :value="true"
              :color="colors.mainPurple"
              class="radioItem pa-5 mb-5"
              id="CheckBox_equipaje_extra"
            >
              <template v-slot:label>
                <div class="d-flex align-center">
                  <h3>Equipaje extra</h3>
                </div>
              </template>
            </v-checkbox>
          </AvisoCobroPorEquipaje>

          <v-divider></v-divider>
          <!-- DECLARACIÓN SALUD -->

          <h2 class="pb-3 pt-8">¿Declaración de salud?</h2>
          <h3>
            ¿Ha presentado alguno de los siguientes síntomas durante las últimas
            24 horas?
          </h3>
          <ul>
            <li>Fiebre</li>
            <li>Tos</li>
            <li>Dificultad respiratoria</li>
            <li>Dolor de cabeza</li>
            <li>Dolor de garganta</li>
            <li>Congestión nasal</li>
            <li>Dolores musculares</li>
            <li>Decaimiento</li>
            <li>Dolor abdominal</li>
            <li>Dolor torácico</li>
            <li>Calofríos</li>
            <li>Pérdida de apetito</li>
            <li>Vómitos</li>
            <li>Diarrea</li>
            <li>Pérdida del gusto o del olfato</li>
          </ul>
          <v-radio-group v-model="salud.sintomas" column>
            <v-radio
              :color="colors.mainPurple"
              label="Si"
              :value="true"
            ></v-radio>
            <v-radio
              :color="colors.mainPurple"
              label="No"
              :value="false"
            ></v-radio>
          </v-radio-group>

          <h3>¿Tiene resultado de PCR positivo en las últimas 2 semanas?</h3>
          <v-radio-group v-model="salud.pcrPositivo" column>
            <v-radio
              :color="colors.mainPurple"
              label="Si"
              :value="true"
            ></v-radio>
            <v-radio
              :color="colors.mainPurple"
              label="No"
              :value="false"
            ></v-radio>
          </v-radio-group>

          <h3>
            ¿Ha tenido contacto estrecho con alguna persona que sea caso
            confirmado o en estudio de Covid-19?
          </h3>
          <v-radio-group v-model="salud.contactoEstrecho" column>
            <v-radio
              :color="colors.mainPurple"
              label="Si"
              :value="true"
            ></v-radio>
            <v-radio
              :color="colors.mainPurple"
              label="No"
              :value="false"
            ></v-radio>
          </v-radio-group>

          <h3>¿Ha estado en otro país en los últimos 10 días?</h3>
          <v-radio-group v-model="salud.haSalidoDelPais" column>
            <v-radio
              :color="colors.mainPurple"
              label="Si"
              :value="true"
            ></v-radio>
            <v-radio
              :color="colors.mainPurple"
              label="No"
              :value="false"
            ></v-radio>
          </v-radio-group>
        </v-sheet>
      </v-carousel-item>

      <!-- LISTA DE PASAJEROS -->

      <v-carousel-item v-if="checkInPasajeros.length >= 1">
        <v-sheet light>
          <table class="mx-auto">
            <tr class="text-center">
              <th>Pasajero</th>
              <th>
                Equipaje <br />
                Mano / cabina / extra
              </th>
            </tr>
            <tr v-for="(pasajero, i) of checkInPasajeros" :key="i">
              <td class="pr-2">
                {{ pasajero.nombre }}
              </td>
              <td class="pr-2 text-center">
                <v-icon small>{{
                  pasajero.equipaje.mano ? "mdi-check" : "mdi-close"
                }}</v-icon>
                <v-icon small>{{
                  pasajero.equipaje.cabina ? "mdi-check" : "mdi-close"
                }}</v-icon>
                <v-icon small>{{
                  pasajero.equipaje.extra ? "mdi-check" : "mdi-close"
                }}</v-icon>
              </td>
            </tr>
          </table>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>

    <!-- STEPPER Y BOTON PARA CONTIUNAR -->

    <div
      v-if="listaPasajeros.length != checkInPasajeros.length"
      class="footer d-flex justify-space-between align-center"
    >
      <v-btn
        class="footer__boton"
        color="transparent"
        elevation="0"
        @click="agregarPasajero"
        :disabled="
          !validacionFormulario || listaPasajeros.length === checkInPasajeros
        "
      >
        <h2>
          {{
            listaPasajeros.length === checkInPasajeros.length + 1
              ? "Continuar"
              : "Siguiente"
          }}
        </h2>
      </v-btn>
      <div>
        <v-icon
          v-for="(pasajero, i) in listaPasajeros"
          :key="i"
          size="10"
          :color="colors.grayDisabled"
          :class="{ active: i == pasajeroVisible }"
          >mdi-circle</v-icon
        >
      </div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Boton from "@/components/Botones/Boton";
import AvisoCobroPorEquipaje from "@/components/PopUps/AvisoCobroPorEquipaje";
import worldCountries from "@/helpers/worldCountries.js";
export default {
  name: "DatosPasajerosCheckIn",
  components: {
    Boton,
    AvisoCobroPorEquipaje,
  },
  props: {
    listaPasajeros: {
      type: Array,
    },
    tipoDeViaje: {
      type: String,
      default: "Vuelos",
    },
    equipajePermitido: {
      type: Object,
      default: {
        mano: true,
        cabina: true,
      },
    },
  },
  data() {
    return {
      pasajeroVisible: 0,
      autocompletar: true,
      actualizarDatos: false,
      documentos: [
        { text: "-Selecciona documento-", disabled: true },
        { text: "RUT", disabled: false },
        { text: "Pasaporte", disabled: false },
      ],
      paises: [],
      //Datos Usuario
      nombre: "",
      documento: "-Selecciona documento-",
      rutSinDigitoVerificador: "",
      digitoVerificador: "",
      numeroPasaporte: "",
      paisDeOrigen: "Chile",
      email: "",
      telefono: "569",
      direccion: "",
      emergencia: {
        nombre: "",
        telefono: "569",
      },
      equipaje: {
        mano: false,
        cabina: false,
        extra: false,
      },
      salud: {
        sintomas: null,
        pcrPositivo: null,
        contactoEstrecho: null,
        haSalidoDelPais: null,
      },
      //Lista de pasajeros
      checkInPasajeros: [],
      enviarCorreoATodos: false,
    };
  },
  computed: {
    ...mapState("Lenguages", ["lenguages", "idioma"]),
    ...mapState(["colors", "logos"]),
    rules() {
      let reglas = {
        name: [
          // (v) => !!v || "Nombre requerido",
          (v) =>
            v.length >= 7 ||
            "El nombre completo debe tener al menos 7 caracteres",
        ],
        email: [
          // (v) => !!v || "Correo electrónico requerido",
          (v) =>
            /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v
            ) || "El correo electrónico ingresado no es válido",
        ],
        documento: [
          (v) => v != this.documentos[0].text || "Selecciona un documento",
        ],
        rut: [(v) => (v.length <= 8 && v.length >= 6) || "Rut no válido"],

        digitoVerificador: [
          (v) => v.length === 1 || "Sólo 1 caracter permitido",
        ],
        required: [(v) => !!v || "Campo requerido"],
      };

      return reglas;
    },
    //Booleanos
    validacionFormulario() {
      if (
        this.nombre.trim() == "" ||
        this.documento === this.documentos[0].text ||
        this.email.trim() == "" ||
        this.direccion.trim() == "" ||
        this.emergencia.nombre.trim() == "" ||
        this.emergencia.telefono == "" ||
        this.emergencia.telefono == "569" ||
        this.salud.sintomas === null ||
        this.salud.pcrPositivo === null ||
        this.salud.contactoEstrecho === null ||
        this.salud.haSalidoDelPais === null
      ) {
        return false;
      } else if (
        this.documento === "RUT" &&
        (this.rutSinDigitoVerificador.trim() == "" ||
          this.digitoVerificador.trim() == "")
      ) {
        return false;
      } else if (
        this.documento === "Pasaporte" &&
        this.numeroPasaporte.trim() == ""
      ) {
        return false;
      } else return true;
    },
  },
  methods: {
    ...mapMutations("Ventas", ["FORMULARIO_CHECK_IN"]),
    agregarPasajero() {
      const telefono = this.telefono;

      if (this.validacionFormulario) {
        const datosUsuario = {
          nombre: this.nombre.trim(),
          documento: this.documento,
          rut: `${this.rutSinDigitoVerificador.trim()}-${this.digitoVerificador
            .trim()
            .toLocaleLowerCase()}`,
          pasaporte: this.numeroPasaporte,
          pais: this.paisDeOrigen,
          email: this.email.trim(),
          telefono: +telefono,
          direccion: this.direccion.trim(),
          emergencia: JSON.parse(JSON.stringify(this.emergencia)),
          equipaje: JSON.parse(JSON.stringify(this.equipaje)),
          salud: JSON.parse(JSON.stringify(this.salud)),
        };

        console.log(datosUsuario);
        this.checkInPasajeros.push(datosUsuario);
        this.pasajeroVisible++;
        if (this.listaPasajeros.length > this.checkInPasajeros.length) {
          this.equipaje.mano = false;
          this.equipaje.cabina = false;
          this.equipaje.extra = false;
          this.emergencia.nombre = "";
          this.emergencia.telefono = 569;
          this.salud.sintomas = null;
          this.salud.pcrPositivo = null;
          this.salud.contactoEstrecho = null;
          this.salud.haSalidoDelPais = null;

          this.autocompletarDatosDeUsuario();
        } else if (
          this.listaPasajeros.length === this.checkInPasajeros.length
        ) {
          this.FORMULARIO_CHECK_IN(this.checkInPasajeros);
        }
      }
    },
    autocompletarDatosDeUsuario() {
      const separarRut = this.listaPasajeros[this.pasajeroVisible].rut
        ? this.listaPasajeros[this.pasajeroVisible].rut.split("-")
        : "";

      this.nombre = this.listaPasajeros[this.pasajeroVisible].nombre
        ? this.listaPasajeros[this.pasajeroVisible].nombre
        : "";

      this.email = this.listaPasajeros[this.pasajeroVisible].email
        ? this.listaPasajeros[this.pasajeroVisible].email
        : "";

      this.rutSinDigitoVerificador = separarRut ? separarRut[0] : "";

      this.digitoVerificador = separarRut ? separarRut[1] : "";

      this.pasaporte = this.listaPasajeros[this.pasajeroVisible].pasaporte
        ? this.listaPasajeros[this.pasajeroVisible].pasaporte
        : "";

      this.paisDeOrigen = this.listaPasajeros[this.pasajeroVisible].nacionalidad
        ? this.listaPasajeros[this.pasajeroVisible].nacionalidad
        : "Chile";

      this.telefono = this.listaPasajeros[this.pasajeroVisible].telefono
        ? this.listaPasajeros[this.pasajeroVisible].telefono
        : "569";

      this.direccion = this.listaPasajeros[this.pasajeroVisible].direccion
        ? this.listaPasajeros[this.pasajeroVisible].direccion
        : "";

      if (this.listaPasajeros[this.pasajeroVisible].pasaporte != "")
        this.documento = "Pasaporte";
      if (this.listaPasajeros[this.pasajeroVisible].rut != "")
        this.documento = "RUT";
    },
  },

  async mounted() {
    await this.autocompletarDatosDeUsuario();
    this.paises = worldCountries;
  },
};
</script>

<style lang="scss" scoped>
.radioItem {
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.1);
  border-radius: 14px;
  height: 58px;
  color: $main-black;
}

.footer {
  &__boton {
    color: $main-purple;
    text-transform: initial;
    letter-spacing: initial;
    &:hover {
      cursor: pointer;
    }
  }
}
.active {
  color: $main-purple !important;
}
</style>
