<template>
  <v-dialog v-model="dialogCancelar" max-width="750" class="cancelarVuelo">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <slot> </slot>
      </div>
    </template>
    <v-sheet>
      <v-row no-gutters class="pa-6">
        <v-col cols="12" md="6" class="cancelarVuelo__Left">
          <v-container>
            <h2>¿Estás seguro de cancelar tu vuelo?</h2>

            <div class="d-flex align-start justify-center pt-8">
              <div>
                <h3 class="pr-3">{{ infoVuelo.origen }}</h3>
              </div>
              <img :src="iconoAeronave" alt="iconoAeronave" width="50" />
              <h3 class="pl-3">{{ infoVuelo.destino }}</h3>
            </div>
            <h3 class="text-center pt-3 pb-8">{{ infoVuelo.time }}</h3>

            <div @click="aceptaCancelar">
              <Boton
                texto="Sí, cancelar"
                color="red"
                width="100%"
                class="my-2"
              />
            </div>
            <div @click="dialogCancelar = false">
              <Boton texto="Ahora no" :dark="false" width="100%" class="my-2" />
            </div>
          </v-container>
        </v-col>
        <v-col cols="12" md="6" class="cancelarVuelo__right">
          <v-container>
            <p>
              Al cancelar, estás renunciando a recibir el servicio contratado.
              Esta acción no admite reembolsos por la compra.
            </p>
            <p>
              Consulta
              <a href="/terminos-y-condiciones/compra" target="_blank"
                >términos y condiciones</a
              >.
            </p>
          </v-container>
        </v-col>
      </v-row>
    </v-sheet>
  </v-dialog>
</template>

<script>
import firebase from "firebase";
import moment from "moment";

import Boton from "@/components/Botones/Boton";
import { mapState } from "vuex";
export default {
  name: "CancelarVuelo",
  components: {
    Boton,
  },
  data() {
    return {
      dialogCancelar: false,
    };
  },
  props: {
    infoVuelo: {
      required: true,
      type: Object,
    },
    idCompra: {
      required: true,
      type: String,
    },
    tipoDeViaje: {
      required: true,
      type: String,
    },
  },
  computed: {
    ...mapState(["icons"]),
    ...mapState("Auth", ["usuario"]),
    iconoAeronave() {
      const iconoAvion = this.icons.purple.plane;
      const iconoHelicoptero = this.icons.purple.helicopter.straight;

      return this.infoVuelo.aeronave === "avion"
        ? iconoAvion
        : iconoHelicoptero;
    },
  },
  methods: {
    async aceptaCancelar() {
      try {
        const cancelarRef = firebase.firestore().collection("viajesCancelados");
        const idYNombreDeUsuario = {
          id: this.usuario.uid,
          name: this.usuario.displayName,
          email: this.usuario.email,
        };
        const data = {
          date: moment().format("YYYY-MM-DD HH:mm"),
          vuelo: this.infoVuelo,
          idCompra: this.idCompra,
          usuario: idYNombreDeUsuario,
          tipoDeViaje: this.tipoDeViaje,
        };
        await cancelarRef.add(data);

        const datosDelUsuarioFB = await firebase
          .firestore()
          .collection("usuarios")
          .doc(this.usuario.uid)
          .get();

        let tipoDeViaje = this.tipoDeViaje;

        let datosDelUsuario = datosDelUsuarioFB.data();
        let historialDeComprasSegunElTipoDeViaje =
          tipoDeViaje == "Experiencias"
            ? datosDelUsuario.misExperiencias
            : datosDelUsuario.misVuelos;

        historialDeComprasSegunElTipoDeViaje = historialDeComprasSegunElTipoDeViaje.map(
          (h) => {
            if (h.id == this.idCompra) {
              if (h.details.ida.id == data.vuelo.id) {
                h.details.ida.data.status = "Cancelado";
              }
              if (h.details.vuelta) {
                if (h.details.vuelta.id == data.vuelo.id) {
                  h.details.ida.data.status = "Cancelado";
                }
              }
            }
            return h;
          }
        );

        const historialModificado =
          tipoDeViaje == "Experiencias"
            ? { misExperiencias: historialDeComprasSegunElTipoDeViaje }
            : { misVuelos: historialDeComprasSegunElTipoDeViaje };
        await firebase
          .firestore()
          .collection("usuarios")
          .doc(this.usuario.uid)
          .update(historialModificado);

        this.dialogCancelar = false;
        this.$toast.open({
          message: "Vuelo cancelado",
          position: "top",
          duration: 3000,
        });
        this.$router.push({ name: "MiPerfil" });
      } catch (error) {
        this.dialogCancelar = false;
        this.$toast.open({
          message: error.message,
          position: "top",
          duration: 3000,
          type: "error",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cancelarVuelo {
  @include roundedAndElevated;
  &__left {
    background: #fff;
  }
  &__right {
    background: $main-purple;
    color: $main-white;
    border-radius: 0 0 10px 10px;
    a {
      text-decoration: underline;
    }
  }
}

@media (min-width: 960px) {
  .cancelarVuelo {
    &__left {
    }
    &__right {
      border-radius: 0 10px 10px 0;
    }
  }
}
</style>