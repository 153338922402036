<template>
  <div id="VuelosEscogidos" class="vuelosEscogidos" v-if="viaje.data">
    <div class="vuelosEscogidos__overlay d-none d-md-block">
      <div class="vuelosEscogidos__overlay__bgImg" :style="imgFondo"></div>
    </div>
    <v-container class="vuelosEscogidos__info mx-auto">
      <div class="d-flex align-items-center">
        <FlechaAtras :blanco="flechaAtrasBlanca" class="d-sm-none " />
        <div class="vuelosEscogidos__titulo ms-2 my-sm-5">
          <h1>
            {{ tipoViaje === "Vuelos" ? "Vuelo" : "Experiencia" }}
            {{ tituloRuta }}
          </h1>
          <h2 v-if="viaje.data.promocionXPlus1">
            Vuelan {{ viaje.data.capacidad }}, pagan
            {{ viaje.data.capacidad - 1 }}
          </h2>
        </div>
      </div>
      <v-row no-gutters class="mx-auto ">
        <div class="d-flex flex-wrap">
          <!-- Mapa e info general -->
          <v-col cols="12" md="8" class="pr-md-3 order-2 order-sm-1 ">
            <Map
              class="vuelosEscogidos__mapSection mb-4"
              :direccionOrigen="viaje.data.direccionOrigen"
              :direccionDestino="viaje.data.direccionDestino"
              :origenVuelo="viaje.data.origen"
              :destinoVuelo="viaje.data.destino"
              :sobrevuelo="viaje.data.sobrevuelo"
            />
            <v-container class="vuelosEscogidos__infoGeneral shaped px-5">
              <h2 class="pb-4">
                {{
                  idioma == "es"
                    ? viaje.data.destino
                    : viaje.data.destinoEN
                    ? viaje.data.destinoEN
                    : viaje.data.destino
                }}
                <span class="puntoOrigen d-none" v-if="viaje.data.origen">
                  desde
                  {{
                    viajeTipoReserva
                      ? "el aire"
                      : viaje.data.aerodromoOrigen
                      ? viaje.data.aerodromoOrigen
                      : viaje.data.origen
                  }}
                </span>
              </h2>
              <div v-if="tipoDeViaje === 'Experiencias'" class="mb-5">
                <p class="pb-3" style="white-space: pre-wrap">
                  {{
                    idioma == "es"
                      ? viaje.data.descripcion
                      : viaje.data.descripcionEN
                  }}
                </p>

                <div class="pb-3" v-if="viaje.data.direccionOrigen">
                  <h3>{{ lenguages[idioma].detalle.salidaYLlegada }}</h3>

                  <div>
                    <p
                      v-if="
                        viaje.data.direccionDestino && !viaje.data.sobrevuelo
                      "
                    >
                      {{
                        viaje.data.aerodromoDestino
                          ? viaje.data.aerodromoDestino
                          : ""
                      }}<br v-if="viaje.data.aerodromoDestino" />
                      {{ viaje.data.direccionDestino }}
                    </p>
                    <br />
                    <p v-if="viaje.data.tiempoDeVuelo">
                      {{ lenguages[idioma].detalle.tiempoDeVuelo }} :
                      <span class="font-weight-medium">
                        {{ viaje.data.tiempoDeVuelo }}
                        {{ lenguages[idioma].detalle.minutos }}
                      </span>
                    </p>
                    <p v-if="viaje.data.tiempoTotal">
                      <b>{{ lenguages[idioma].detalle.tiempoTotal }} :</b>
                      <span class="font-weight-medium">
                        {{ viaje.data.tiempoTotal }}
                        {{ lenguages[idioma].detalle.minutos }}
                      </span>
                    </p>
                  </div>
                </div>

                <!-- <h3>{{ lenguages[idioma].detalle.detallesDelVuelo }}</h3> -->
                <ul class="listaDetalles" v-if="viaje.data.detalles">
                  <!-- <li v-if="idsDeExperienciasPromocionada.includes(viaje.id) && operadorVuelo.id == '6iX6KDVugtkZfXMyWUSa'">
                    Fechas disponibles:

                    <a
                      class="links"
                      href="/viajes/Experiencias/Os9tcSwzhEvO3fElhtRZ"
                    >
                      4</a
                    >,
                    <a
                      class="links"
                      href="/viajes/Experiencias/Sv2cVLB6ORblDVk5xUZq"
                    >
                      5</a
                    >,
                    <a
                      class="links"
                      href="/viajes/Experiencias/dcrC4aFdsYSneT2k1Itt"
                    >
                      8</a
                    >,

                    <a
                      class="links"
                      href="/viajes/Experiencias/B3G3yFwVHIXfDHlWgtHb"
                    >
                      11
                    </a>
                    y

                    <a
                      class="links"
                      href="/viajes/Experiencias/QA2inpkmTru7dTXE5Ioe"
                    >
                      12
                    </a>
                    de diciembre
                  </li> -->

                  <li
                    v-for="(detalle, i) of idioma == 'es'
                      ? viaje.data.detalles
                      : viaje.data.detallesEN"
                    :key="i"
                    class="my-3"
                  >
                    {{ detalle }}
                  </li>
                </ul>

                <div v-if="false">
                  <h3>{{ lenguages[idioma].detalle.fechasDisponibles }}</h3>
                  <div class="d-flex flex-column align-center">
                    <v-date-picker
                      :allowedDates="allowedDates"
                      all
                      locale="es"
                      color="#645dc7"
                      elevation="3"
                      v-model="picker"
                      :min="picker"
                      readonly
                      :show-current="false"
                    ></v-date-picker>
                  </div>
                </div>
              </div>

              <v-row no-gutters>
                <v-col
                  v-for="(detalle, i) of detallesVuelo"
                  :key="i"
                  cols="12"
                  md="6"
                  class="d-flex align-center pb-6"
                  v-if="!viajeTipoReserva && detalle.texto"
                >
                  <img
                    :src="detalle.icono"
                    alt="icono equipaje"
                    class="pr-5"
                    :class="{ 'pr-7': i > 0 }"
                    height="25"
                  />
                  <h4>{{ detalle.texto }}</h4>
                </v-col>
              </v-row>
            </v-container>

            <v-container class="seguridad shaped px-5 mt-2 pt-5">
              <div>
                <h3 class="pb-2">
                  {{
                    viaje.data.privado
                      ? lenguages[idioma].detalle.coordinacionDeTuVueloPrivado
                      : lenguages[idioma].detalle
                          .coordinacionDeTuVueloCompartido
                  }}
                  :
                </h3>

                <div v-if="viaje.data.privado">
                  <div
                    v-for="(parrafo, i) in lenguages[idioma].detalle
                      .coordinacionesExperienciaPrivada"
                    :key="i"
                    style="margin-bottom: -20px"
                  >
                    <p>{{ parrafo }}</p>
                    <br />
                  </div>
                </div>
                <div v-else>
                  <div
                    v-for="(parrafo, i) in lenguages[idioma].detalle
                      .coordinacionesExperienciaCompartida"
                    :key="i"
                    style="margin-bottom: -20px"
                  >
                    <p>{{ parrafo }}</p>
                    <br />
                  </div>
                </div>
              </div>
              <div class="pb-4">
                <h3 class="py-2">
                  {{ lenguages[idioma].detalle.medidasDeSeguridadTitulo }}:
                </h3>
                <p>
                  {{ lenguages[idioma].detalle.medidasDeSeguridad[0] }}
                </p>
                <br />
                <p>
                  {{ lenguages[idioma].detalle.medidasDeSeguridad[1] }}
                  {{ lenguages[idioma].detalle.medidasDeSeguridad[2] }}
                </p>
              </div>
            </v-container>

            <!-- Calificaciones -->
            <v-container
              class="shaped my-3 pa-5"
              v-if="viaje.data.comentarios && viaje.data.comentarios.length > 0"
            >
              <Calificaciones :comentarios="viaje.data.comentarios" />
            </v-container>
          </v-col>

          <!-- 'Ticket' con detalles -->
          <v-col cols="12" md="4" class="px-0 pl-md-3 order-1 order-sm-2">
            <v-container
              class="vuelosEscogidos__detalles d-flex flex-column justify-center pa-2"
            >
              <CardReservarVuelo
                class="vuelosEscogidos__detalles__reservar"
                :docViaje="viaje"
                :tipoDeViaje="tipoViaje"
              />
              <div class=" pt-4 py-sm-1 px-sm-3 " v-show="monedas.dolar">
                <Slider :imagenes="bgImg" />
              </div>
            </v-container>
          </v-col>
        </div>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import FlechaAtras from "@/components/FlechaAtras";
import CardReservarVuelo from "@/components/Cards/CardReservarVuelo";
import Slider from "@/components/Detalle/Slider";
import Calificaciones from "@/components/Detalle/Calificaciones";
import Map from "@/components/Map";
import { mapGetters, mapState } from "vuex";
import moment from "moment";
import firebase from "firebase";
export default {
  name: "DetallesDeviaje",
  components: {
    FlechaAtras,
    CardReservarVuelo,
    Map,
    Slider,
    Calificaciones,
  },
  props: ["id", "tipoDeViaje"],
  data() {
    return {
      picker: new Date().toISOString().substr(0, 10),

      idsDeExperienciasPromocionada: [
        "QA2inpkmTru7dTXE5Ioe",
        "B3G3yFwVHIXfDHlWgtHb",
        "dcrC4aFdsYSneT2k1Itt",
        "Sv2cVLB6ORblDVk5xUZq",
        "air2lITg0lANHJxlcLr6",
      ],
      verMasComentarios: false,
      viaje: { data: {} },
      viajes: { data: {} },
      tipoViaje: "Vuelos",
      bgImg:
        "https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/assets%2Fpasajero%2Fimagen%202login%20bg%20mobile.png?alt=media&token=4a358437-1ec1-4e68-9c6a-eb4ff3533007",
    };
  },
  computed: {
    ...mapState("Lenguages", ["lenguages", "idioma"]),
    ...mapState("Experiencias", ["monedas"]),
    ...mapState(["icons", "colors"]),
    ...mapState("Ventas", ["vuelosElegidos"]),
    ...mapState("Operadores", ["operadores"]),
    ...mapGetters("Vuelos", ["getVueloById", "getVuelosByOperador"]),
    ...mapGetters("Experiencias", [
      "getExperienciaById",
      "getExperienciasByOperador",
    ]),

    tituloRuta() {
      return this.tipoViaje === "Vuelos"
        ? `${this.viaje.data.origen} - ${this.viaje.data.destino}`
        : `${this.viaje.data.destino}`;
    },
    imgFondo() {
      const src = typeof this.bgImg === "string" ? this.bgImg : this.bgImg[0];

      return { backgroundImage: `url(${src})` };
    },
    detallesVuelo() {
      const { equipaje } = this.viaje.data;
      if(!equipaje) return []
      let equipajePermitido;
      if (equipaje && equipaje.mano && !equipaje.cabina) {
        equipajePermitido = "de mano";
      } else if (equipaje && !equipaje.mano && equipaje.cabina) {
        equipajePermitido = "de cabina";
      } else if (equipaje && equipaje.mano && equipaje.cabina) {
        equipajePermitido = "de mano y de cabina";
      } else {
        equipajePermitido = "de mano";
      }

      return [
        {
          icono: this.icons.purple.luggage,
          texto: this.viaje.data.equipaje
            ? "Equipaje " + equipajePermitido
            : "Equipaje de mano y de cabina",
        },
        {
          icono: this.icons.purple.seat,
          texto: this.viaje.data.capacidad + " asientos",
        },
      ];
    },
    operadorVuelo() {
      const operador = this.operadores.find(
        (o) => o.id === this.viaje.data.operador.id
      );

      return operador;
    },
    comentariosOperador() {
      const comentariosOperador = this.operadorVuelo.calificaciones;

      return comentariosOperador;
    },
    comentariosAMostrar() {
      return this.verMasComentarios
        ? this.comentariosOperador.slice(0, 10)
        : this.comentariosOperador.slice(0, 4);
    },
    infoGeneralComentarios() {
      return {
        cantidad: this.comentariosOperador.length,
        rating: this.operadorVuelo.rating,
      };
    },
    cantidadVuelosDelOperador() {
      // const todosLosVuelos = this.getVuelosByOperador(
      //   this.viaje.data.operador.id
      // ).concat(this.getExperienciasByOperador(this.viaje.data.operador.id));
      // const sinVuelosCancelados = todosLosVuelos.filter(
      //   (v) => v.data.status != "cancelado"
      // );
      return 0;
    },
    flechaAtrasBlanca() {
      return this.$vuetify.breakpoint.width >= 960 ? true : false;
    },
    viajeTipoReserva() {
      return !!this.viaje.data.reserva;
    },
  },
  methods: {
    fechaComentario(fecha) {
      return moment(fecha);
    },
    allowedDates(value) {
      const dias = [];
      let { diasDisponibles, fechas } = this.viaje.data;
      if (!diasDisponibles) diasDisponibles = {};
      if (!fechas) fechas = [];
      diasDisponibles.forEach((d, i) => (d.value ? dias.push(i) : false));
      const fechaCriterio = new Date(value).getDay();
      return dias.includes(fechaCriterio) || fechas.includes(value);
    },
  },
  async created() {
    try {
      this.tipoViaje = this.tipoDeViaje;

      const getDataFromFb = await firebase
        .firestore()
        .collection(this.tipoDeViaje.toLowerCase())
        .doc(this.id)
        .get();

      this.viaje = { data: getDataFromFb.data(), id: this.id };

      this.bgImg = this.viaje.data.img;
    } catch (error) {
      this.$router.push({ name: "Error" });
    }
  },
  title() {
    return `Ver vuelo - HummingBird`;
  },
};
</script>

<style lang="scss" scoped>
.links {
  color: $main-purple !important;
  font-weight: 700;
}

#VuelosEscogidos,
.vuelosEscogidos {
  padding-top: 100px;
  background-color: $gray-background;
  min-height: 100vh;
  &__info {
    position: absolute;
    top: 112px;
    left: 50%;
    transform: translateX(-50%);
    padding-bottom: 90px;
  }
  h1 {
    font-size: 20px;
    font-weight: 400;
    color: $main-black;
  }
  &__mapSection {
    height: 280px;
    width: 100%;
  }
  &__infoGeneral {
    h4 {
      font-size: 16px;
    }
  }
}
h3 {
  padding-bottom: 8px;
}

.shaped {
  background: $main-white;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.1);
  border-radius: 14px;
}

.verMas {
  color: $main-purple;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
}
.puntoOrigen {
  font-size: 16px;
}
.listaDetalles {
  list-style: initial;
}
.seguridad {
  padding-bottom: 24px;
  ul {
    list-style: initial;
    li {
      padding-bottom: 8px;
    }
  }
  a {
    text-decoration: underline;
    color: $main-purple !important;
    font-weight: 600;
  }
}
@media (min-width: 960px) {
  #VuelosEscogidos,
  .vuelosEscogidos {
    h1 {
      font-size: 36px;
      font-weight: 400;
      line-height: 45px;
      color: $main-white;
    }
    &__overlay {
      height: 450px;
      background-color: $main-black;
      animation-name: traslateFromTop;
      animation-duration: 0.8s;
      &__bgImg {
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        opacity: 0.4;
        filter: blur(4px);
      }
    }
    &__mapSection {
      height: 480px;
    }
    &__detalles {
      background: $main-white;
      box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.1);
      border-radius: 14px;
      position: sticky;
      top: 124px;
    }
  }
}
</style>
