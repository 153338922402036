<template>
  <v-container class="cardReservar">
    <div class="cardReservar__header d-flex justify-space-between">
      <div class="d-flex align-center">
        <img :src="icono" height="50" class="cardReservar__header__icon mr-2" />
        <div>
          <p>
            {{
              docViaje.data.aeronave === "avion"
                ? lenguages[idioma].cardReservarVuelo.avion
                : lenguages[idioma].cardReservarVuelo.helicoptero
            }}
          </p>
          <p>{{ docViaje.d }}</p>
          <!-- <h4 v-if="!experienciaTipoReserva">{{ ventasVuelo }}</h4> -->
        </div>
      </div>

      <div
        @mouseover="tooltip = true"
        @mouseleave="tooltip = false"
        @click="goToWhatsapp"
      >
        <v-btn icon>
          <v-tooltip top v-model="tooltip" color="#645dc7">
            <template v-slot:activator="{}">
              <v-icon large color="#25D366">
                mdi-whatsapp
              </v-icon>
            </template>
            <span>
              <v-icon color="white">
                mdi-whatsapp
              </v-icon>
              ¡Consulta sobre esta experiencia!</span
            >
          </v-tooltip>
        </v-btn>
      </div>
    </div>
    <v-divider class="mt-3 mb-5" />
    <v-row no-gutters align="center">
      <v-col cols="6">
        <h3>{{ lenguages[idioma].cardReservarVuelo.origen }}</h3>
        <h4>{{ docViaje.data.origen }}</h4>
      </v-col>
      <v-col cols="6">
        <h3>{{ lenguages[idioma].cardReservarVuelo.destino }}</h3>
        <h4>{{ docViaje.data.destino }}</h4>
      </v-col>
      <v-col cols="12" v-if="!experienciaTipoReserva">
        <h3>{{ lenguages[idioma].cardReservarVuelo.fechaDelVuelo }}</h3>
        <h4>
          {{ fechaDelVuelo }}
        </h4>
      </v-col>
      <v-col cols="6" v-if="!experienciaTipoReserva">
        <h3>Hora <span v-if="docViaje.data.horaLlegada">salida</span></h3>
        <h4>{{ docViaje.data.horaDespegue }}</h4>
      </v-col>
      <v-col
        cols="6"
        v-if="docViaje.data.horaLlegada && !experienciaTipoReserva"
      >
        <h3>{{ lenguages[idioma].cardReservarVuelo.horaDeLlegada }}</h3>
        <h4>{{ docViaje.data.horaLlegada }}</h4>
      </v-col>
      <v-col cols="12" v-if="!experienciaTipoReserva">
        <h3>{{ lenguages[idioma].cardReservarVuelo.capacidad }}</h3>
        <h4>
          {{ docViaje.data.capacidad }}
          {{ lenguages[idioma].cardReservarVuelo.pasajeros }}
        </h4>
      </v-col>
    </v-row>

    <div class="d-flex justify-center" v-if="!precioEnCLPBasadoEnDolarDeHoy">
      <v-progress-circular indeterminate color="#645dc7"></v-progress-circular>
    </div>
    <div
      class="d-flex py-4 align-center justify-center"
      v-if="precioEnCLPBasadoEnDolarDeHoy"
    >
      <div class="text-center mr-2">
        <h2 v-if="docViaje.data.privado">
          {{ docViaje.data.capacidad }}
          {{ lenguages[idioma].cardReservarVuelo.pasajeros }}
        </h2>
        <h2 v-else>
          {{
            pasajeros > 1
              ? pasajeros + " " + lenguages[idioma].cardReservarVuelo.pasajeros
              : pasajeros + " " + lenguages[idioma].cardReservarVuelo.pasajero
          }}
        </h2>

        <h3 class="ahorro" v-if="docViaje.data.promocionXPlus1">
          {{ lenguages[idioma].cardReservarVuelo.ahorro }} ${{
            precioEnMiles(docViaje.data.precio)
          }}
        </h3>
      </div>
      <div v-if="!docViaje.data.privado">
        <v-btn
          x-small
          dark
          fab
          :color="colors.mainPurple"
          :disabled="pasajeros === 1"
          @click="pasajeros--"
          class="mr-1"
          style="font-size:20px"
          >-</v-btn
        >
        <v-btn
          x-small
          dark
          fab
          :color="colors.mainPurple"
          :disabled="false"
          @click="pasajeros++"
          class="ml-1"
          style="font-size:20px"
          >+</v-btn
        >
      </div>
    </div>

    <div
      class="d-flex align-center justify-center"
      v-if="precioEnCLPBasadoEnDolarDeHoy"
    >
      <h2 class="pl-1 mb-1">
        {{
          docViaje.data.privado
            ? lenguages[idioma].cardReservarVuelo.precioTotal
            : lenguages[idioma].cardReservarVuelo.precioPorPasajero
        }}

        <span>
          {{
            elVueloEsPrivado
              ? `US$${precioEnMiles(precioEnDolares * capacidad)}`
              : `US$${precioEnMiles(precioEnDolares)}`
          }}
        </span>
      </h2>
    </div>

    <h6 class="text-center" v-if="precioEnCLPBasadoEnDolarDeHoy">
      <span>
        {{
          elVueloEsPrivado
            ? `$${precioEnMiles(precioEnCLPBasadoEnDolarDeHoy * capacidad)}`
            : `$${precioEnMiles(precioEnCLPBasadoEnDolarDeHoy)}`
        }}
        CLP
      </span>
    </h6>

    <div @click="reservarVuelo" v-if="precioEnCLPBasadoEnDolarDeHoy">
      <Boton
        :texto="lenguages[idioma].cardReservarVuelo.reservar"
        width="100%"
        class="mt-4"
        height="45px"
      />
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import Boton from "@/components/Botones/Boton";
import CambiarPasajeros from "@/components/CambiarPasajeros";

export default {
  name: "CardReservarVuelo",
  components: {
    Boton,
    CambiarPasajeros,
  },
  async created() {
    const viaje = { ...this.docViaje };

    this.viaje = this.docViaje;
  },
  mounted() {
    const { viaje } = this;
    const { data } = viaje;
    const { promocionXPlus1, capacidad } = data;

    this.pasajeros = promocionXPlus1 ? capacidad - 1 : 1;
    setTimeout(() => (this.tooltip = false), 4000);
  },
  data() {
    return {
      viaje: null,
      pasajeros: 1,
      tooltip: true,
    };
  },
  props: {
    docViaje: {
      type: Object,
      required: true,
    },
    tipoDeViaje: {
      type: String,
    },
  },
  computed: {
    ...mapState("Lenguages", ["lenguages", "idioma"]),
    ...mapState(["icons", "colors"]),
    ...mapGetters("Vuelos", ["getVueloById"]),
    ...mapGetters("Experiencias", ["getExperienciaById"]),
    ...mapGetters("Ventas", ["montoTotal"]),
    ...mapState("Experiencias", ["moneda", "monedas"]),

    fechaDelVuelo() {
      return this.docViaje?.data?.fecha
        ?.split("-")
        ?.reverse()
        ?.join("-");
    },

    elVueloEsPrivado() {
      const {
        data: { privado },
      } = this.docViaje;

      return privado;
    },

    capacidad() {
      const {
        data: { capacidad },
      } = this.docViaje;

      return capacidad;
    },

    precioEnDolares() {
      const {
        monedas: { dolar },
      } = this;
      const {
        data: { precio, precioEnDolares },
      } = this.docViaje;
      if (precioEnDolares) return +precioEnDolares;
      const precioEnDolar = precio / dolar;
      return Math.ceil(precioEnDolar).toLocaleString("de");
    },
    precioEnCLPBasadoEnDolarDeHoy() {
      const {
        monedas: { dolar },
        docViaje: {
          data: { precio, precioEnDolares },
        },
      } = this;
      if (precioEnDolares) return +precioEnDolares * +dolar;
      return Math.ceil(precio);
    },
    icono() {
      const iconoAvion = this.icons.purple.plane;
      const iconoHelicoptero = this.icons.purple.helicopter.straight;
      const aeronave = this.docViaje.data.aeronave;
      let icono = aeronave === "avion" ? iconoAvion : iconoHelicoptero;
      return icono;
    },
    ventasVuelo() {
      const ventas = this.docViaje.data.ventas;

      return ventas === 0
        ? "¡Se el primer pasajero!"
        : ventas === 1
        ? "1 pasajero ha comprado este vuelo"
        : `${ventas} pasajeros han comprado este vuelo`;
    },
    precioEnMiles: () => (numero) => {
      if (numero === undefined) {
        numero = 0;
      }

      return new Intl.NumberFormat("de-DE").format(Math.round(numero));
    },
    asientosDisponibles() {
      return this.docViaje.data.capacidad - this.docViaje.data.ventas;
    },
    experienciaTipoReserva() {
      return !!this.docViaje.data.reserva;
    },
  },
  methods: {
    ...mapMutations("Ventas", ["VUELOS_ELEGIDOS"]),
    goToWhatsapp() {
      const {
        docViaje: {
          data: { destino },
          id,
        },
      } = this;
      const url = "https://hummingbird.cl/viajes/Experiencias/" + id;
      const text = `Hola, me gustaría consultar por ${destino}
      ${url}
      `;
      window.location = `https://api.whatsapp.com/send/?phone=56956350990&text=${text.trim()}&app_absent=0`;
    },
    async reservarVuelo() {
      const {
        docViaje: {
          data: { privado, capacidad },
        },
      } = this;
      await this.VUELOS_ELEGIDOS({
        ida: this.docViaje,
        info: {
          tipoDeVuelo: "Solo ida",
          tipoDeViaje: this.tipoDeViaje,
          pasajeros: privado ? capacidad : this.pasajeros,
        },
      });
      this.$router.push({ name: "Pago" });
    },
  },
};
</script>

<style lang="scss" scoped>
.ahorro {
  color: $main-green;
}
.precio-final {
  color: $main-orange;
}
.cardReservar {
  background: $main-white;
  &__header {
    &__icon {
      @include flex;
      border-radius: 50%;
      width: 45px;
      height: 45px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      padding: 5px;
    }
  }
}
</style>
