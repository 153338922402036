<template>
  <section id="CheckIn" class="checkIn" v-if="documentoCompra">
    <v-container class="mx-auto checkIn__wrapper">
      <v-row>
        <v-col cols="1" class="pb-9">
          <FlechaAtras />
        </v-col>

        <v-col cols="12" md="7">
          <h1 class="pb-9">Web Check-in</h1>

          <v-container class="checkIn__datosPasajeros mb-5 d-md-none">
            <DetallesVueloCheckIn
              :idVuelo="idVuelo"
              :pasajeros="documentoCompra.details.listaPasajeros.length"
            />
          </v-container>

          <v-container class="checkIn__datosPasajeros mb-5">
            <DatosPasajerosCheckIn
              :listaPasajeros="documentoCompra.details.listaPasajeros"
              :tipoDeViaje="documentoCompra.details.info.tipoDeViaje"
              :equipajePermitido="equipajePermitido"
            />
          </v-container>

          <v-container>
            <div @click="realizarCheckIn">
              <Boton
                texto="Finalizar Check-in"
                :deshabilitar="!checkInPasajeros"
                fontSize="24px"
                heigth="fit-content"
              />
            </div>
          </v-container>
        </v-col>

        <v-col
          cols="4"
          lg="4"
          xl="3"
          class="checkIn__detalles pl-md-3 d-none d-md-block"
        >
          <h1 class="pb-9">Detalles de vuelo</h1>
          <div class="checkIn__detalles__resumenVuelo pa-2">
            <DetallesVueloCheckIn
              :idVuelo="idVuelo"
              :pasajeros="documentoCompra.details.listaPasajeros.length"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import DatosPasajerosCheckIn from "@/components/FormulariosPasajeros/DatosPasajerosCheckIn";
import FlechaAtras from "@/components/FlechaAtras";
import DetallesVueloCheckIn from "@/components/Cards/DetallesVueloCheckIn";
import Boton from "@/components/Botones/Boton";
import { mapGetters, mapMutations, mapState } from "vuex";
import firebase from "firebase";

export default {
  name: "CheckIn",
  components: {
    DatosPasajerosCheckIn,
    FlechaAtras,
    DetallesVueloCheckIn,
    Boton,
  },
  props: {
    idCompra: {
      type: String,
    },
    idVuelo: {
      type: String,
    },
  },
  data() {
    return {
      documentoCompra: null,
    };
  },
  computed: {
    ...mapState(["icons", "logos", "colors"]),
    ...mapState("Ventas", ["checkInPasajeros"]),
    ...mapState("Auth", ["usuario"]),
    ...mapGetters("Vuelos", ["getVueloById"]),
    equipajePermitido() {
      if (this.documentoCompra) {
        let vuelo =
          this.documentoCompra.details.ida.id === this.idVuelo
            ? this.documentoCompra.details.ida
            : this.documentoCompra.details.vuelta;

        return vuelo.data.equipaje
          ? vuelo.data.equipaje
          : { mano: true, cabina: true };
      }
    },
  },
  methods: {
    ...mapMutations("Ventas", ["AGREGAR_DATOS_VENTA"]),
    async realizarCheckIn() {
      try {
        const {
          idCompra,
          idVuelo,
          documentoCompra,
          checkInPasajeros,
          usuario,
          getVueloById,
        } = this;
        const { misVuelos, misExperiencias } = usuario;
        const userId = documentoCompra.userData.id;

        // verificar si viaje es vuelo o experiencia
        const getVuelo = getVueloById(idVuelo);
        const esVuelo = getVuelo ? true : false;

        //MARCAR CHECKIN COMO REALIZADO (TRUE) EN COMPRA/DETAILS/IDA O VUELTA//
        const mapViajes = (v) => {
          if (v.details.ida.id === idVuelo && v.id === idCompra) {
            v.details.ida.checkInDone = true;
            return v;
          } else if (
            v.details.vuelta &&
            v.details.vuelta.id === idVuelo &&
            v.id === idCompra
          ) {
            v.details.vuelta.checkInDone = true;
            return v;
          }
          return v;
        };
        let ventasActualizadas = null;

        // cambio de estado de check-in
        if (esVuelo) {
          ventasActualizadas = misVuelos.map(mapViajes);
        } else {
          ventasActualizadas = misExperiencias.map(mapViajes);
        }

        const firestoreUser = firebase
          .firestore()
          .collection("usuarios")
          .doc(userId);

        esVuelo
          ? await firestoreUser.update({ misVuelos: ventasActualizadas })
          : await firestoreUser.update({ misExperiencias: ventasActualizadas });

        // PUSH EN EL VUELO ORIGINAL DE LOS DATOS DEL CHECK-IN
        const firebaseVuelo = esVuelo
          ? firebase.firestore().collection("vuelos").doc(idVuelo)
          : firebase.firestore().collection("experiencias").doc(idVuelo);

        const checkIn = {
          data: checkInPasajeros,
          date: new Date(),
          user: { name: usuario.displayName, id: usuario.uid },
        };

        await firebaseVuelo.update({
          checkIn: firebase.firestore.FieldValue.arrayUnion(checkIn),
        });

        this.$toast.open({
                    message: "Check-in realizado",
                    position: "top",
                    duration: 3000,
                });
        this.$router.push({ name: "MiPerfil" });
      } catch (error) {
        console.log(error);
      }
    },
  },
  async created() {
    try {
      let data;
      await firebase
        .firestore()
        .collection("ventas")
        .doc(this.idCompra)
        .get()
        .then((d) => {
          data = d.data();
          this.documentoCompra = data;
        });
    } catch (error) {
      console.log(error);
    }
  },
  title() {
    return `Web check-in - HummingBird`;
  },
};
</script>

<style lang="scss" scoped>
#CheckIn,
.checkIn {
  background: $main-white;
  min-height: 100vh;
  padding-top: 70px;
  padding-bottom: 95px;
  &__datosPasajeros {
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.1);
    border-radius: 14px;
    background: $main-white;

    p {
      font-size: 12px;
    }
  }
  &__detalles {
    color: $main-black;
    &__resumenVuelo {
      background: $main-white;
      box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.1);
      border-radius: 14px;
    }
  }
}

@media (min-width: 960px) {
  #CheckIn,
  .checkIn {
    background-color: $gray-background;
    padding-top: 100px;
    &__datosPasajeros {
      max-width: 575px;
      p {
        font-size: 18px;
      }
    }
    &__detalles {
    &__resumenVuelo {
      position: sticky;
      top: 130px;
    }
  }
  }
}
</style>

