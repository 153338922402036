<template>
  <div>
    <Loader
      v-if="destinoGeo === {} || origenGeo === {} || loading"
      id="LoadingMap"
      texto="CARGANDO MAPA"
    />
    <GmapMap
      v-else
      :center="center"
      :options="mapOptions"
      :zoom="zoom"
      map-type-id="hybrid"
      id="Map"
    >
      <GmapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m"
        @click="center = m"
        :clickable="true"
      />
      <GmapCircle
        v-if="esSobreVuelo"
        v-for="(pin, index) in markers"
        :key="(index + 1) * 10"
        :center="pin"
        :radius="25000"
        :visible="true"
        :options="{
          fillColor: colors.mainPurple,
          fillOpacity: 0.4,
          strokeColor: '#FF0',
          strokeOpacity: 0.5,
        }"
      >
      </GmapCircle>
      <gmap-polygon
        :options="{
          strokeColor: '#FF0',
          strokeOpacity: '.7',
          strokeWeight: '5',
        }"
        :geodesic="true"
        :paths="path"
      ></gmap-polygon>
    </GmapMap>
  </div>
</template>

<script>
import Loader from "@/components/Loader";
import { mapState } from "vuex";
export default {
  name: "Map",
  props: {
    origenVuelo: {
      type: String,
    },
    destinoVuelo: {
      type: String,
    },
    direccionOrigen: {
      type: String,
    },
    direccionDestino: {
      type: String,
    },
    sobrevuelo: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Loader,
  },
  data() {
    return {
      origenGeo: {},
      destinoGeo: {},
      mapOptions: {
        zoomControl: true,
        fullscreenControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
      },
    };
  },
  computed: {
    ...mapState("Ventas", ["vuelosElegidos"]),
    ...mapState(["colors"]),
    destino() {
      let destino;
      if (this.direccionDestino) {
        destino = this.direccionDestino;
      } else if (this.destinoVuelo) {
        destino = this.destinoVuelo;
      } else {
        destino = this.vuelosElegidos.ida.data.direccionDestino
          ? this.vuelosElegidos.ida.data.direccionDestino
          : this.vuelosElegidos.ida.data.destino;
      }
      let format = destino.split(" ").join("+");
      return `${format},Chile`;
    },
    origen() {
      let origen;
      if (this.direccionOrigen) {
        origen = this.direccionOrigen;
      } else if (this.origenVuelo) {
        origen = this.origenVuelo;
      } else {
        origen = this.vuelosElegidos.ida.data.direccionOrigen
          ? this.vuelosElegidos.ida.data.direccionOrigen
          : this.vuelosElegidos.ida.data.origen;
      }
      let format = origen.split(" ").join("+");
      return `${format},Chile`;
    },
    markers() {
      let markers = [this.origenGeo, this.destinoGeo];
      return markers;
    },
    path() {
      return [this.origenGeo, this.destinoGeo];
    },
    center() {
      const lat = (this.origenGeo.lat + this.destinoGeo.lat) / 2;
      const lng = (this.origenGeo.lng + this.destinoGeo.lng) / 2;

      return { lat, lng };
    },
    zoom() {
      if (
        Math.abs(this.origenGeo.lat - this.destinoGeo.lat) < 1 &&
        Math.abs(this.origenGeo.lng - this.destinoGeo.lng) < 0.5
      ) { 
        return 9;
      } else if (
        Math.abs(this.origenGeo.lng - this.destinoGeo.lng) < 1 &&
        Math.abs(this.origenGeo.lat - this.destinoGeo.lat) < 0.5
      ) {
        return 8;
      } else if (Math.abs(this.origenGeo.lat - this.destinoGeo.lat) > 10) {
        return 4;
      } else {
        return 8;
      }
    },
    loading() {
      return this.origenGeo === {} ||
        this.destinoGeo === {} ||
        !this.center.lat ||
        !this.center.lng
        ? true
        : false;
    },
    esSobreVuelo() {
      if (this.sobrevuelo || this.destinoGeo === this.origenGeo) return true;
      else return false;
    },
  },
  methods: {
    async getLatAndLng(place, punto) {
      try {
        let res = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${place}&key=AIzaSyCerQOr4AumyOzutGeohenCHyLLRTkNHCo`
        );
        let resJSON = await res.json();
        let latAndLng = await resJSON.results[0].geometry.location;

        punto === "origen"
          ? (this.origenGeo = latAndLng)
          : (this.destinoGeo = latAndLng);
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.getLatAndLng(this.origen, "origen");
    this.getLatAndLng(this.destino, "destino");
  },
};
</script>

<style lang="scss" scoped>
#Map´ {
  animation-name: scaleYFromBottom;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 0.5s;
}

#Map,
#LoadingMap {
  height: 100%;
  width: 100%;
}

@media (min-width: 960px) {
  #Map,
  #LoadingMap {
    border-radius: 0 0 20px 20px !important;
    filter: drop-shadow(0px 7px 10px rgba(0, 0, 0, 0.1));
    // height: 480px;
  }
}
</style>