<template>
  <div
    id="VuelosEscogidos"
    class="vuelosEscogidos"
    v-if="documentoCompra && viaje.data"
  >
    <div class="vuelosEscogidos__overlay d-none d-md-block">
      <div :style="imgFondo" class="vuelosEscogidos__overlay__bgImg"></div>
    </div>
    <v-container class="vuelosEscogidos__info mx-auto">
      <v-row no-gutters class="mx-auto">
        <v-col cols="1" class="">
          <FlechaAtras :blanco="true" class="d-none d-md-block" />
          <FlechaAtras class="d-md-none" />
        </v-col>
        <!-- Mapa e info general -->
        <v-col cols="12" md="6" lg="7" xl="8" class="pr-md-3">
          <div class="vuelosEscogidos__titulo pb-6">
            <h1>
              {{ tipoDeViaje === "Vuelos" ? "Vuelo" : "Experiencia a" }}
            </h1>
            <div class="d-flex justify-space-between align-baseline">
              <h1>{{ tituloRuta }}</h1>

              <div class="d-none d-md-block">
                <Boton
                  texto="Check-in realizado"
                  :deshabilitar="true"
                  v-if="checkInRealizado"
                  color="gray"
                />
                <v-btn
                  v-else
                  class="rounded-lg botonConTextoBlanco"
                  :color="colors.mainPurple"
                  dark
                  :disabled="
                    checkInRealizado ||
                      deshabilitarCheckIn(
                        `${viaje.data.fecha} ${viaje.data.horaDespegue}`
                      )
                  "
                  width="100%"
                  :to="`/check-in/${idCompra}/${id}`"
                >
                  web check-in
                </v-btn>
                <!-- <router-link
                  v-else
                  :to="`/check-in/${idCompra}/${id}`"
                  class="d-none d-md-block"
                >
                  <Boton
                    texto="web check-in"
                    width="100%"
                    :deshabilitar="
                      checkInRealizado ||
                      deshabilitarCheckIn(
                        `${viaje.data.fecha} ${viaje.data.horaDespegue}`
                      )
                    "
                  />
                </router-link> -->
              </div>
            </div>
          </div>
          <Map
            :origenVuelo="viaje.data.origen"
            :destinoVuelo="viaje.data.destino"
            class="vuelosEscogidos__mapSection mb-4"
          />
          <v-container
            class="vuelosEscogidos__infoGeneral d-none d-md-block shaped px-11"
          >
            <h2 class="pb-4">
              {{ viaje.data.destino }}
              <span class="puntoOrigen" v-if="viaje.data.origen">
                desde
                {{
                  viaje.data.aerodromoOrigen
                    ? viaje.data.aerodromoOrigen
                    : viaje.data.origen
                }}
              </span>
            </h2>
            <div v-if="tipoDeViaje === 'Experiencias'">
              <p class="pb-3">
                {{ viaje.data.descripcion }}
              </p>

              <div class="pb-3" v-if="viaje.data.direccionOrigen">
                <h3>Salida y llegada</h3>
                <p>
                  <span v-if="viaje.data.direccionDestino">Salida: </span
                  >{{
                    viaje.data.aerodromoOrigen
                      ? viaje.data.aerodromoOrigen
                      : ""
                  }}<br v-if="viaje.data.aerodromoOrigen" />{{
                    viaje.data.direccionOrigen
                  }}
                </p>
                <p v-if="viaje.data.direccionDestino">
                  Llegada:
                  {{
                    viaje.data.aerodromoDestino
                      ? viaje.data.aerodromoDestino
                      : ""
                  }}<br v-if="viaje.data.aerodromoDestino" />
                  {{ viaje.data.direccionDestino }}
                </p>
              </div>

              <div class="pb-3">
                <h3>Fecha</h3>
                <p>
                  {{
                    viaje.data.fecha
                      .split("-")
                      .reverse()
                      .join("-")
                  }}
                </p>
                <p>
                  {{ viaje.data.horaDespegue }}
                  <span v-if="viaje.data.horaLlegada">
                    a {{ viaje.data.horaLlegada }}</span
                  >
                  hrs
                </p>
              </div>
              <h3>Detalles vuelo</h3>
              <ul class="listaDetalles" v-if="viaje.data.detalles">
                <li
                  v-for="(detalle, i) of viaje.data.detalles"
                  :key="i"
                  class="my-3"
                >
                  {{ detalle }}
                </li>
              </ul>
            </div>
            <h3 class="pb-7">Detalles vuelo</h3>
            <v-row no-gutters>
              <v-col
                v-for="(detalle, i) of detallesVuelo"
                :key="i"
                cols="6"
                class="d-flex align-center pb-6"
              >
                <img
                  :src="detalle.icono"
                  alt="icono equipaje"
                  class="pr-5"
                  height="25"
                />
                <h4>{{ detalle.texto }}</h4>
              </v-col>
            </v-row>
            <Boton
              texto="Check-in realizado"
              width="100%"
              :deshabilitar="true"
              v-if="checkInRealizado"
            />
            <v-btn
              v-else
              class="rounded-lg botonConTextoBlanco"
              :color="colors.mainPurple"
              :dark="
                !deshabilitarCheckIn(
                  `${viaje.data.fecha} ${viaje.data.horaDespegue}`
                )
              "
              :disabled="
                checkInRealizado ||
                  deshabilitarCheckIn(
                    `${viaje.data.fecha} ${viaje.data.horaDespegue}`
                  )
              "
              width="100%"
              :to="`/check-in/${idCompra}/${id}`"
            >
              web check-in
            </v-btn>
          </v-container>
        </v-col>

        <!-- 'Ticket' con detalles -->
        <v-col cols="12" md="5" lg="4" xl="3" class="pl-md-3">
          <div class="d-md-none">
            <Boton
              texto="Check-in realizado"
              width="100%"
              :deshabilitar="true"
              v-if="checkInRealizado"
            />
            <v-btn
              v-else
              class="rounded-lg botonConTextoBlanco"
              :color="colors.mainPurple"
              :dark="
                !deshabilitarCheckIn(
                  `${viaje.data.fecha} ${viaje.data.horaDespegue}`
                )
              "
              :disabled="
                checkInRealizado ||
                  deshabilitarCheckIn(
                    `${viaje.data.fecha} ${viaje.data.horaDespegue}`
                  )
              "
              width="100%"
              :to="`/check-in/${idCompra}/${id}`"
            >
              web check-in
            </v-btn>
          </div>
          <v-container
            class="vuelosEscogidos__detalles d-flex flex-column justify-center"
          >
            <TIcketCompraRealizada :documentoCompra="documentoCompra">
              <v-row no-gutters>
                <v-col cols="12" md="6">
                  <h3 class="itemTitle">Fecha de compra</h3>
                  <p class="itemContent">
                    {{
                      new Date(documentoCompra.date).toLocaleDateString(
                        "es-ES",
                        {
                          weekday: "long",
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        }
                      )
                    }}
                  </p>
                  <h3 class="itemTitle">Monto total</h3>
                  <p class="itemContent">
                    ${{ precioEnMiles(documentoCompra.details.total) }}
                  </p>
                  <h3 class="itemTitle">Código de venta</h3>
                  <p class="itemContent">
                    {{ idCompra }}
                  </p>
                  <h3 class="itemTitle">Comprado por:</h3>
                  <p class="itemContent">
                    {{ documentoCompra.userData.displayName }}
                  </p>
                </v-col>
                <v-col cols="12" md="6">
                  <h3 class="itemTitle">Lista pasajeros</h3>
                  <ol>
                    <li
                      v-for="(pasajero, i) of documentoCompra.details
                        .listaPasajeros"
                      :key="i"
                      class="itemContent"
                    >
                      {{ pasajero.nombre }}
                      {{ pasajero.rut }}
                    </li>
                  </ol>
                </v-col>
              </v-row>
            </TIcketCompraRealizada>

            <CancelarVuelo
              :infoVuelo="viaje.data"
              :idCompra="idCompra"
              :tipoDeViaje="tipoDeViaje"
            >
              <Boton
                texto="Cancelar vuelo"
                color="red"
                width="100%"
                class="mt-4"
                height="45px"
              />
            </CancelarVuelo>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import FlechaAtras from "@/components/FlechaAtras";
import TIcketCompraRealizada from "@/components/Cards/TIcketCompraRealizada";
import CancelarVuelo from "@/components/PopUps/CancelarVuelo";
import Map from "@/components/Map";
import Boton from "@/components/Botones/Boton";
import { mapActions, mapGetters, mapState } from "vuex";
import firebase from "firebase";
import moment from "moment";
export default {
  name: "MiVueloDetalle",
  components: {
    FlechaAtras,
    CancelarVuelo,
    TIcketCompraRealizada,
    Map,
    Boton,
  },
  props: ["id", "idCompra"],
  data() {
    return {
      tipoDeViaje: "",
      viaje: {},
      documentoCompra: null,
      bgImg:
        "https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/assets%2Fpasajero%2Fimagen%202login%20bg%20mobile.png?alt=media&token=4a358437-1ec1-4e68-9c6a-eb4ff3533007",
    };
  },
  computed: {
    ...mapState(["icons", "colors"]),
    ...mapState("Auth", ["usuario"]),
    ...mapState("Ventas", ["vuelosElegidos"]),
    ...mapGetters("Vuelos", ["getVueloById"]),
    ...mapGetters("Experiencias", ["getExperienciaById"]),
    tituloRuta() {
      return this.tipoDeViaje === "Vuelos"
        ? `${this.viaje.data.origen} - ${this.viaje.data.destino}`
        : `${this.viaje.data.destino}`;
    },
    imgFondo() {
      const src = typeof this.bgImg === "string" ? this.bgImg : this.bgImg[0];

      return { backgroundImage: `url(${src})` };
    },
    detallesVuelo() {
      return [
        {
          icono: this.icons.purple.luggage,
          texto: "Equipaje de mano y de cabina",
        },
        {
          icono: this.icons.purple.seat,
          texto: this.puestosDisponibles + " asientos disponibles",
        },
      ];
    },
    puestosDisponibles() {
      const capacidadIda = this.viaje.data.capacidad;
      const ventasIda = this.viaje.data.ventas;
      const asientosDisponiblesIda = capacidadIda - ventasIda;
      return asientosDisponiblesIda;
    },
    precioEnMiles: () => (numero) => {
      if (numero === undefined) {
        numero = 0;
      }

      return numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    checkInRealizado() {
      const { misVuelos, misExperiencias } = this.usuario;
      let checkInDone;

      const documentoCompraEnPerfilDeUsuario =
        this.tipoDeViaje === "Vuelos"
          ? misVuelos.find((v) => v.id === this.idCompra)
          : misExperiencias.find((v) => v.id === this.idCompra);

      const vueloEsElDeIda =
        documentoCompraEnPerfilDeUsuario.details.ida.id === this.id;

      if (vueloEsElDeIda) {
        checkInDone = documentoCompraEnPerfilDeUsuario.details.ida.checkInDone;
      } else
        checkInDone =
          documentoCompraEnPerfilDeUsuario.details.vuelta.checkInDone;

      return checkInDone;
    },
  },
  methods: {
    ...mapActions("Ventas", ["getVentaById"]),
    cancelarVuelo() {},
    async getVenta() {
      try {
        await firebase
          .firestore()
          .collection("ventas")
          .doc(this.idCompra)
          .onSnapshot((doc) => {
            this.documentoCompra = doc.data();
          });
      } catch (error) {
        console.log(error);
        if (this.documentoCompra === undefined)
          this.router.push({ name: "Error" });
        return { data: {} };
      }
    },
    deshabilitarCheckIn(fechaYHora) {
      let fechaDosDiasAntes = moment(fechaYHora).subtract(2, "d");

      if (fechaDosDiasAntes.isSameOrAfter(moment())) {
        return true;
      } else {
        return false;
      }
    },
  },
  async created() {
    await this.getVenta();
    //

    let getVuelo = await this.getVueloById(this.id);
    let getExperiencia = await this.getExperienciaById(this.id);
    if (getVuelo || getExperiencia) {
      this.viaje = (await getVuelo) ? getVuelo : getExperiencia;
      this.bgImg = this.viaje.data.img;
      this.tipoDeViaje = getVuelo ? "Vuelos" : "Experiencias";
    } else this.$router.push({ name: "MiPerfil" });
  },
  title() {
    return `Mi vuelo - HummingBird`;
  },
};
</script>

<style lang="scss" scoped>
#VuelosEscogidos,
.vuelosEscogidos {
  padding-top: 100px;
  background-color: $gray-background;
  min-height: 100vh;

  &__info {
    position: absolute;
    top: 112px;
    left: 50%;
    transform: translateX(-50%);
    padding-bottom: 100px;
  }
  h1 {
    font-size: 24px;
    font-weight: 400;
    line-height: 45px;
    color: $main-black;
  }
  &__mapSection {
    height: 280px;
    width: 100%;
  }
  &__infoGeneral {
    h4 {
      font-size: 16px;
    }
  }
}

.botonConTextoBlanco {
  color: #fff !important;
}

.shaped {
  background: $main-white;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.1);
  border-radius: 14px;
}

.itemTitle {
  font-size: 15px;
}
.itemContent {
  font-size: 12px;
}

.puntoOrigen {
  font-size: 16px;
}

@media (min-width: 960px) {
  #VuelosEscogidos,
  .vuelosEscogidos {
    h1 {
      font-size: 36px;
      font-weight: 400;
      line-height: 45px;
      color: $main-white;
    }
    &__overlay {
      height: 450px;
      background-color: $main-black;
      animation-name: traslateFromTop;
      animation-duration: 0.8s;
      &__bgImg {
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        opacity: 0.4;
        filter: blur(4px);
      }
    }
    &__mapSection {
      height: 480px;
    }
    &__detalles {
      background: $main-white;
      box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.1);
      border-radius: 14px;
    }
  }
}
</style>
